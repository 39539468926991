.m-1 {
  margin: calc(1 * var(--u-base)) !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: calc(2 * var(--u-base)) !important;
}

.m-3 {
  margin: calc(4 * var(--u-base)) !important;
}

.m-4 {
  margin: calc(6 * var(--u-base)) !important;
}

.m-5 {
  margin: calc(8 * var(--u-base)) !important;
}

// margin top bottom
.my-1 {
  margin-top: calc(1 * var(--u-base)) !important;
  margin-bottom: calc(1 * var(--u-base)) !important;
}

.my-2 {
  margin-top: calc(2 * var(--u-base)) !important;
  margin-bottom: calc(2 * var(--u-base)) !important;
}

.my-3 {
  margin-top: calc(4 * var(--u-base)) !important;
  margin-bottom: calc(4 * var(--u-base)) !important;
}

.my-4 {
  margin-top: calc(6 * var(--u-base)) !important;
  margin-bottom: calc(6 * var(--u-base)) !important;
}

.my-5 {
  margin-top: calc(8 * var(--u-base)) !important;
  margin-bottom: calc(8 * var(--u-base)) !important;
}

// margin left right
.mx-1 {
  margin-left: calc(1 * var(--u-base)) !important;
  margin-right: calc(1 * var(--u-base)) !important;
}

.mx-2 {
  margin-left: calc(2 * var(--u-base)) !important;
  margin-right: calc(2 * var(--u-base)) !important;
}

.mx-3 {
  margin-left: calc(4 * var(--u-base)) !important;
  margin-right: calc(4 * var(--u-base)) !important;
}

.mx-4 {
  margin-left: calc(6 * var(--u-base)) !important;
  margin-right: calc(6 * var(--u-base)) !important;
}

.mx-5 {
  margin-left: calc(8 * var(--u-base)) !important;
  margin-right: calc(8 * var(--u-base)) !important;
}

// ml margin left
.ml-1 {
  margin-left: calc(1 * var(--u-base)) !important;
}

.ml-2 {
  margin-left: calc(2 * var(--u-base)) !important;
}

.ml-3 {
  margin-left: calc(4 * var(--u-base)) !important;
}

.ml-4 {
  margin-left: calc(6 * var(--u-base)) !important;
}

.ml-5 {
  margin-left: calc(8 * var(--u-base)) !important;
}

//mr right margin
.mr-1 {
  margin-right: calc(1 * var(--u-base)) !important;
}

.mr-2 {
  margin-right: calc(2 * var(--u-base)) !important;
}

.mr-3 {
  margin-right: calc(4 * var(--u-base)) !important;
}

.mr-4 {
  margin-right: calc(6 * var(--u-base)) !important;
}

.mr-5 {
  margin-right: calc(8 * var(--u-base)) !important;
}

//mt top margin
.mt-1 {
  margin-top: calc(1 * var(--u-base)) !important;
}

.mt-2 {
  margin-top: calc(2 * var(--u-base)) !important;
}

.mt-3 {
  margin-top: calc(4 * var(--u-base)) !important;
}

.mt-4 {
  margin-top: calc(6 * var(--u-base)) !important;
}

.mt-5 {
  margin-top: calc(8 * var(--u-base)) !important;
}

//mb bottom margin
.mb-1 {
  margin-bottom: calc(1 * var(--u-base)) !important;
}

.mb-2 {
  margin-bottom: calc(2 * var(--u-base)) !important;
}

.mb-3 {
  margin-bottom: calc(4 * var(--u-base)) !important;
}

.mb-4 {
  margin-bottom: calc(6 * var(--u-base)) !important;
}

.mb-5 {
  margin-bottom: calc(8 * var(--u-base)) !important;
}
