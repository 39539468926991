//base
@import "./base/import";

body {
  font-size: var(--font-base);
}

.custom-modal {
  width: var(--create-modal-w);
}

.pt-nav {
  padding-top: 50px !important;
}

.bottomLine {
  border-bottom: 0.2px solid var(--col-dark) !important;
  opacity: 0.2 !important;
}

.bottomLine-header {
  border-bottom: 0.2px solid var(--col-dark) !important;
}

.flow-icon {
  svg {
    width: 20px !important;
    height: 20px !important;
  }

  width: 20px !important;
  height: 20px !important;
}

.textButton {
  color: var(--col-prime) !important;
}

.ai-model {
  width: 500px;
}

.selected-block {
  background-color: var(--col-primary-20) !important;
}

.selected-block .opacity-0 {
  opacity: 1 !important;
}

.disabled {
  opacity: 0.5 !important;
}

.Accordian {
  width: 18vw !important;
  background-color: transparent !important;
}

.pluginLogo {
  width: 42px;
  margin-right: 6px;
}

.width-when-show-ai {
  width: 42vw !important;
}

.componentspagebox {
  display: flex !important;
  padding: 8px;
  flex-direction: column !important;
  background-color: beige !important;
}

// .log-block:has(.logBlocks) {
//     border-bottom: 1px solid;
//     border-image: linear-gradient(to right, rgb(167, 167, 167), rgba(167, 167, 167, 0)) 1;
//     /* Adjust the direction and colors as needed */
// }

// .log-block:has(.selectedBlock){
//     border-bottom: 1px solid;
//     border-image: linear-gradient(to right, rgb(255, 255, 255), rgba(167, 167, 167, 0)) 1;
// }

.mainLogBlocksContainer {
  min-width: 600px;
  height: 100vh;
}

.title-textfield:focus-within {
  opacity: 0.7 !important;
}

.logsAndFlow {
  margin-left: 250px !important;
}

.logHeadIcons {
  font-size: 10px !important;
}

.right-slider-width {
  width: var(--right-slider-w) !important;
  box-sizing: border-box !important;
}

.mainLogBlocksValueContainer {
  min-width: 370px !important;
  margin-right: 10px;
  margin-top: 50px;
  position: sticky;
  bottom: 10px;
  padding: 10px 15px;
  height: 100vh;
  box-sizing: border-box;
  overflow: scroll;
}

.ipValueForLog {
  font-size: 12px;
}

.sendIcon {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.loadingicon {
  right: 10px;
  transform: translateY(-50%);
}

//blockstyling
.project-block {
  height: 30px !important;
}

.create-block {
  color: var(--create-btn-color) !important;
}

.block {
  cursor: pointer;

  &:hover {
    background-color: var(--col-primary-5);

    .opacity-0 {
      opacity: 1 !important;
    }
  }
}

.disabled-block {
  opacity: 0.4;
}

.line-height {
  line-height: normal !important;
}

.font-1rem {
  font-size: 1rem !important;
}

.font-2rem {
  font-size: 2rem !important;
}

.font-3rem {
  font-size: 3rem !important;
}

.slider-title-box {
  height: 35px !important;
}

.trigger-padding {
  padding-left: 20px !important;
}

.project-icon {
  width: 18px !important;
}

// Global search Heading
.global-search-h {
  pointer-events: none !important;
  text-transform: uppercase !important;
  font-size: var(--font-small-heading) !important;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  color: var(--col-primary-draft) !important;
  margin-left: 16px !important;
  max-width: var(--script-slider-w) !important;
}

.css-1rt9ipu-MuiInputBase-root-MuiTablePagination-select,
.MuiTablePagination-input {
  width: unset !important;
}

.MuiBox-root {
  box-sizing: border-box !important;
}

.MuiCollapse-root {
  width: 100% !important;
}

.dialogMinMax {
  min-width: var(--dilaog-min-width) !important;
  max-width: var(--dilaog-max-width) !important;
}

.event-box {
  cursor: pointer !important;
  padding: 12px;
  width: 240px !important;
  height: 65px;
  gap: 12px;
  &__selected {
    background-color: rgba(30, 117, 186, 0.05);
    pointer-events: none;
  }
  &:hover {
    background-color: rgba(30, 117, 186, 0.02);
  }

  &__with-description {
    width: 280px !important;
    height: 100px !important;
  }
  &__with-integration {
    height: 200px !important;
    padding: 0 !important;
    width: 100% !important;

    &:hover {
      background-color: transparent !important;
      .opacity-0 {
        opacity: 1 !important;
      }
    }
  }
  &__footer {
    border-top: 0.2px solid #ccc;
  }

  border: 1px solid rgba(233, 231, 226, 1);

  .event-box-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }
}

.events-container {
  gap: 12px;
}

.flow-description {
  color: rgb(107, 111, 118) !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.current-enabled-flow-box {
  padding: 16px;
  height: 113px !important;
  width: 240px !important;
  border: 1px solid rgba(233, 231, 226, 1);
  background-color: var(--col-primary-20);

  &:hover {
    .actionBtnContainer {
      opacity: 1 !important;
    }
  }

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.enabled-flow-box {
  padding: 12px;
  &__embed {
    padding: 0;
  }
  &:hover {
    background-color: var(--col-primary-5);
  }

  height: 122px !important;
  width: 240px;
  border: 1px solid rgba(233, 231, 226, 1);

  background-color: rgba(255, 255, 255, 1);

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.enabled-script-flow-box {
  padding: 16px;

  &:hover {
    background-color: var(--col-primary-5);
  }

  height: 113px !important;
  width: 240px !important;
  border: 1px solid rgba(233, 231, 226, 1);
  background-color: rgba(255, 255, 255, 1);

  &:hover {
    .actionBtnContainer {
      opacity: 1 !important;
    }
  }

  .flow-box-text {
    cursor: text;
    word-wrap: pre-wrap;
    text-overflow: ellipsis !important;
    overflow-x: hidden;
  }
}

.searchbar-width {
  width: 300px;
}

a .hover-fit {
  width: 320px;
}
.info-color {
  cursor: pointer;
  color: var(--col-link);
  &:hover {
    text-decoration: underline;
  }
}
.header-iframe-title {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
@media (max-width: 890px) {
  .header-iframe-preview-section,
  .header-iframe {
    flex-wrap: wrap;
  }
}
textarea {
  outline: none !important;
}
.h-50vh {
  height: fit-content !important;
}
.published_workflow {
  background-color: #fffdf4 !important;
}

.step-draggable-container {
  min-height: 36px !important;
}
.workflow__flow {
  width: 700px !important;
  max-width: 700px !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: #000 !important; /* Replace with your desired color */
}
.icon-size-20 {
  width: 20px !important;
}

.ml-72 {
  margin-left: 72px !important;
}
