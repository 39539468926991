.p-0 {
  padding: 0 !important;
}

.py-1 {
  padding-top: calc(1 * var(--u-base)) !important;
  padding-bottom: calc(1 * var(--u-base)) !important;
}

.py-2 {
  padding-top: calc(2 * var(--u-base)) !important;
  padding-bottom: calc(2 * var(--u-base)) !important;
}

.py-3 {
  padding-top: calc(4 * var(--u-base)) !important;
  padding-bottom: calc(4 * var(--u-base)) !important;
}

.py-4 {
  padding-top: calc(6 * var(--u-base)) !important;
  padding-bottom: calc(6 * var(--u-base)) !important;
}

.py-5 {
  padding-top: calc(8 * var(--u-base)) !important;
  padding-bottom: calc(8 * var(--u-base)) !important;
}

//px right left padding
.px-1 {
  padding-left: calc(1 * var(--u-base)) !important;
  padding-right: calc(1 * var(--u-base)) !important;
}

.px-2 {
  padding-left: calc(2 * var(--u-base)) !important;
  padding-right: calc(2 * var(--u-base)) !important;
}

.px-3 {
  padding-left: calc(4 * var(--u-base)) !important;
  padding-right: calc(4 * var(--u-base)) !important;
}

.px-4 {
  padding-left: calc(6 * var(--u-base)) !important;
  padding-right: calc(6 * var(--u-base)) !important;
}

.px-5 {
  padding-left: calc(8 * var(--u-base)) !important;
  padding-right: calc(8 * var(--u-base)) !important;
}

//p overall padding
.p-1 {
  padding: calc(1 * var(--u-base)) !important;
}

.p-2 {
  padding: calc(2 * var(--u-base)) !important;
}

.p-3 {
  padding: calc(4 * var(--u-base)) !important;
}

.p-4 {
  padding: calc(6 * var(--u-base)) !important;
}

.p-5 {
  padding: calc(8 * var(--u-base)) !important;
}

//pl left padding
.pl-0 {
  padding-left: calc(0 * var(--u-base)) !important;
}
.pl-1 {
  padding-left: calc(1 * var(--u-base)) !important;
}

.pl-2 {
  padding-left: calc(2 * var(--u-base)) !important;
}

.pl-3 {
  padding-left: calc(4 * var(--u-base)) !important;
}

.pl-4 {
  padding-left: calc(6 * var(--u-base)) !important;
}

.pl-5 {
  padding-left: calc(8 * var(--u-base)) !important;
}

.pl-workflow {
  padding-left: 80px !important;
}
.pl-header {
  padding-left: 72px !important;
}
.pl-35 {
  padding-left: 35px !important;
}

.pl-30 {
  padding-left: 30px !important;
}
.pl-28 {
  padding-left: 24px !important;
}
//pr right padding
.pr-1 {
  padding-right: calc(1 * var(--u-base)) !important;
}

.pr-2 {
  padding-right: calc(2 * var(--u-base)) !important;
}

.pr-3 {
  padding-right: calc(4 * var(--u-base)) !important;
}

.pr-4 {
  padding-right: calc(6 * var(--u-base)) !important;
}

.pr-5 {
  padding-right: calc(8 * var(--u-base)) !important;
}

//pt top padding
.pt-1 {
  padding-top: calc(1 * var(--u-base)) !important;
}

.pt-2 {
  padding-top: calc(2 * var(--u-base)) !important;
}

.pt-3 {
  padding-top: calc(4 * var(--u-base)) !important;
}

.pt-4 {
  padding-top: calc(6 * var(--u-base)) !important;
}

.pt-5 {
  padding-top: calc(8 * var(--u-base)) !important;
}

.pt-12 {
  padding-top: calc(12 * var(--u-base)) !important;
}

//pb bottom padding
.pb-1 {
  padding-bottom: calc(1 * var(--u-base)) !important;
}

.pb-2 {
  padding-bottom: calc(2 * var(--u-base)) !important;
}

.pb-3 {
  padding-bottom: calc(4 * var(--u-base)) !important;
}

.pb-4 {
  padding-bottom: calc(6 * var(--u-base)) !important;
}

.pb-5 {
  padding-bottom: calc(8 * var(--u-base)) !important;
}

.pb-120 {
  padding-bottom: calc(30 * var(--u-base)) !important;
}
